import React, { ReactNode } from "react";
import { createSignal } from "react-signal";

// List of available signals
export const MemoryUpdatedSignal = createSignal();
export const MemoryCreatedSignal = createSignal();
export const MemoryDeletedSignal = createSignal();
export const AccountInvitationSentSignal = createSignal();

interface SignalProviderProps {
  children: ReactNode;
}

export const SignalProvider = ({ children }: SignalProviderProps) => {
  const BuildProviderTree = (providers) => {
    if (providers.length === 1) {
      return providers[0];
    }
    const A = providers.shift();
    const B = providers.shift();
    return BuildProviderTree([
      ({ children }) => (
        <A>
          <B>{children}</B>
        </A>
      ),
      ...providers,
    ]);
  };

  const Providers = BuildProviderTree([
    MemoryUpdatedSignal.Provider,
    MemoryCreatedSignal.Provider,
    MemoryDeletedSignal.Provider,
    AccountInvitationSentSignal.Provider,
  ]);

  return <Providers>{children}</Providers>;
};

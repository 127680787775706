// Provider imports 'rollbar'
import {
  ErrorBoundary as RollbarErrorBoundary,
  Provider as RollbarProvider,
} from "@rollbar/react"; // Provider imports 'rollbar'
import React from "react";

const ROLLBAR_ACCESS_TOKEN = process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN ?? "";
const ROLLBAR_ENVIRONMENT = process.env.NEXT_PUBLIC_ROLLBAR_ENVIRONMENT ?? "";
const GIT_VER = process.env.NEXT_PUBLIC_GIT_VER ?? "unknown";

const rollbarConfig = {
  enabled: ROLLBAR_ENVIRONMENT !== "local",
  accessToken: ROLLBAR_ACCESS_TOKEN,

  captureUncaught: true,
  captureUnhandledRejections: true,

  payload: {
    environment: ROLLBAR_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: GIT_VER,
        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true,
      },
    },
  },
};

interface RollbarProps {
  children?: React.ReactNode;
}

export function Rollbar({ children, ...others }: RollbarProps) {
  return (
    <RollbarProvider config={rollbarConfig} {...others}>
      <RollbarErrorBoundary>{children}</RollbarErrorBoundary>
    </RollbarProvider>
  );
}

export default Rollbar;
